<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="cog"></span> Einstellungen
            </h3>
          </div>
          <div>
            <div class="uk-button-group">
              <button
                class="uk-button uk-button-primary"
                @click.prevent="store"
                :disabled="submitting"
              >
                <div v-if="submitting" data-uk-spinner="ratio: 0.7" class="uk-margin-small-right"></div>
                Speichern
              </button>
              <div class="uk-inline">
                <button class="uk-button uk-button-primary" type="button" :disabled="submitting">
                  <span uk-icon="icon: triangle-down"></span>
                </button>
                <div
                  ref="storeDropdown"
                  uk-dropdown="animation: uk-animation-slide-top-small; duration: 200;delay-hide: 0;mode: click; boundary: ! .uk-button-group; boundary-align: true;pos: bottom-right"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <a href="#" @click.prevent="storeAndClose">Speichern und Schließen</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <ul data-uk-tab>
          <li><a href="#">Profil</a></li>
        </ul>
        <ul class="uk-switcher uk-margin">
          <li>
            <profile v-model="form" :users="users" :is-admin="false" v-if="form" />
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import TenantService from '@/services/tenant.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Profile from '@/components/Profile'
export default {
  data () {
    return {
      submitting: false,
      users: [],
      form: null
    }
  },
  components: {
    Profile
  },
  mounted () {
    setTimeout(() => {
      this.form = this.$store.state.auth.user.data.tenant.profile
    }, 50)
  },
  methods: {
    async store () {
      this.submitting = true
      try {
        await TenantService.update(this.$store.state.auth.user.data.tenant.id, this.form)
        this.submitting = false
        iziToast.success({
          title: 'OK',
          message: 'Einstellungen gespeichert!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      } catch (error) {
        this.submitting = false
        iziToast.error({
          title: 'Fehler',
          message: 'Es ist ein Fehler aufgetreten!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })
      }
    },
    storeAndClose () {

    }
  }
}
</script>
