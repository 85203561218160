<template>
  <div class="uk-grid" data-uk-grid>
    <div class="uk-width-expand@m">
      <div class="uk-grid uk-child-width-1-1" data-uk-grid>
        <div>
          <div class="uk-card uk-card-default uk-card-small uk-border-rounded">
            <div class="uk-card-body">
              <form class="uk-form-stacked">
                <div class="uk-grid uk-child-width-1-2@m" data-uk-grid>
                  <div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Firma</label>
                      <div class="uk-form-controls">
                        <input :disabled="!isAdmin" class="uk-input" type="text" v-model="value.company" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Vorname</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.firstname" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Nachname</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.lastname" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Adresse</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.street" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">PLZ</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.zipcode" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Ort</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.city" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">E-Mail</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.email" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Telefon</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.phone" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Fax</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.fax" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Geschäftsführer</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.managing_director" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Handelsregister</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.localcourt" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Handelsregisternummer</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.registernumber" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Steuernummer</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.taxNumber" />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">USt-Id</label>
                      <div class="uk-form-controls">
                        <input class="uk-input" type="text" v-model="value.ustid" />
                      </div>
                    </div>
                    <div class="uk-margin" v-if="isAdmin">
                      <label class="uk-form-label">Paket</label>
                      <div class="uk-form-controls">
                        <v-select
                          :options="['Bronze', 'Silber']"
                          v-model="value.package"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Theme</label>
                      <div class="uk-form-controls">
                        <v-select
                          :options="themes"
                          v-model="value.theme"
                          :clearable="false"
                          :reduce="theme => theme.value"
                        />
                      </div>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Logo</label>
                      <select-image
                        :source="value.logo"
                        @image-selected="onImageSelected"
                        @image-removed="onImageRemoved"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-if="users.length" class="uk-margin">
          <h3 class="uk-card-title uk-flex uk-flex-middle">Benutzer</h3>
          <div
            class="uk-card uk-card-default uk-card-small uk-border-rounded uk-margin-large-bottom"
          >
            <div class="uk-card-body uk-padding-remove">
              <table
                class="uk-table uk-table-divider uk-table-small uk-table-middle uk-table-striped uk-table-hover"
              >
                <tr>
                  <th>Benutzername</th>
                  <th>Rollen</th>
                  <th class="uk-width-small"></th>
                </tr>
                <tbody>
                  <tr class="uk-visible-toggle" v-for="user in users" :key="user.id">
                    <td class="uk-text-nowrap">
                      {{ user.identifier }}
                    </td>
                    <td>
                      {{ user.roles.join(",") }}
                    </td>
                    <td class="uk-text-nowrap">
                      <div class="uk-flex uk-flex-right uk-margin-right">
                        <a
                          href="#"
                          class="uk-link-reset uk-margin-small-right"
                          data-uk-tooltip="Benutzername ändern"
                        >
                          <span class="uk-icon" data-uk-icon="user"></span>
                        </a>
                        <a
                          href="#"
                          class="uk-text-warning uk-margin-small-right"
                          data-uk-tooltip="Passwort ändern"
                        >
                          <span class="uk-icon" data-uk-icon="lock"></span>
                        </a>
                        <a href="#" class="uk-text-danger" data-uk-tooltip="Benutzer löschen">
                          <span class="uk-icon" data-uk-icon="trash"></span>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import SelectImage from '@/components/finder/SelectImage'
export default {
  props: ['value', 'users', 'isAdmin'],
  data () {
    return {
      themes: [{
        label: 'Blau',
        value: 'blue'
      }, {
        label: 'Grün',
        value: 'green'
      }, {
        label: 'Rot',
        value: 'red'
      }, {
        label: 'Gelb',
        value: 'yellow'
      }, {
        label: 'Grau',
        value: 'grey'
      }, {
        label: 'Orange',
        value: 'orange'
      }]
    }
  },
  components: {
    vSelect,
    SelectImage
  },
  methods: {
    onImageSelected (image) {
      this.value.logo = image
    },
    onImageRemoved () {
      this.value.logo = ''
    }
  }
}
</script>
